.collapsable-content {
    .collapse-inner {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        line-clamp: 8;
    }

    a.toggle-collapse {
        display: block;
        margin-bottom: 1rem;
        transition: none;

        &::after {
            content: '\f078';
            font-family: $font-awesome;
            font-weight: 400;
            margin-left: 10px;
        }
    }

    &.active {
        .collapse-inner {
            -webkit-line-clamp: 999;
			line-clamp: 999;
        }

        a.toggle-collapse {
            &::after {
                content: '\f077';
            }
        }
    }
}